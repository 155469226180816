<!-- 德育管理 > 德育档案 > 德育报表 -->
<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <!-- 头部筛选 -->
                <div class="filter_disFlex">
                    <div class="filter-wrap">
                        <expand-filter
                            :closeWidth="1070"
                            :formData="formData"
                            @clickBtn="clickBtn"
                            @changeSel="changeSel"
                            marginBottom="0px"
                            @changeBtnFormType="changeBtnFormType"
                        >
                            <template #datePicker="{slotData}">
                                <div class="date-picker-wrap">
                                    <el-date-picker
                                        v-model="slotData.value"
                                        :clearable="false"
                                        type="datetimerange"
                                        start-placeholder="请选择开始时间"
                                        end-placeholder="请选择结束时间"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        :default-time="['00:00:00', '23:59:59']"
                                    >
                                    </el-date-picker>
                                </div>
                            </template>
                        </expand-filter>
                        <div
                            class="button-line"
                            v-has-permi="['SManagerEduMoralReport:export']"
                        ></div>
                        <el-button
                            style="margin-left: 0px"
                            :loading="exportLoading"
                            v-has-permi="['SManagerEduMoralReport:export']"
                            @click="handleExport()"
                            >导出</el-button
                        >
                    </div>
                </div>
                <div
                    ref="content"
                    class="record_content"
                    :style="{
                        height: `${content.height}px`,
                    }"
                    v-loading="loadingTable"
                >
                    <div class="report_top_title_school">
                        <div style="flex: 1"></div>
                        <div class="title">{{ schoolName }}德育考核统计表</div>
                        <div style="flex: 1">
                            <div
                                v-if="sManagerEduMoralReportCheckPerson"
                                class="disFlex checkUserNameList"
                                ref="checkUserNameList"
                            >
                                <span style="flex-shrink: 0">检查人：</span>
                                <!-- 超过5isShow为false -->
                                <div
                                    class="checkUserNameList-name"
                                    ref="checkUserNameListName"
                                >
                                    <span
                                        v-show="!isShow"
                                        v-for="(
                                            item, index
                                        ) in checkUserNameList
                                            .slice(0, 3)
                                            .join('、')"
                                        :key="index"
                                        >{{ item }}</span
                                    >
                                    <span
                                        v-show="isShow"
                                        v-for="(
                                            item, index
                                        ) in checkUserNameList.join('、')"
                                        :key="index + 'a'"
                                        >{{ item }}</span
                                    >
                                </div>
                                <div
                                    style="margin-left: 5px; cursor: pointer"
                                    class="score_img"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="bottom"
                                    >
                                        <img
                                            v-show="!isShow"
                                            :src="moreUrl"
                                            alt=""
                                            style="width: 14px; height: 14px"
                                        />
                                        <div slot="content">
                                            <span
                                                class="personName"
                                                v-for="(
                                                    item, index
                                                ) in checkUserNameList.join(
                                                    '、',
                                                )"
                                                :key="index + 'a'"
                                                >{{ item }}</span
                                            >
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="report_top_title">
                        <div class="topOne">
                            <div class="topOne_mes">
                                <div class="time">
                                    {{
                                        nowDate + " " + nowTime + " " + nowWeek
                                    }}
                                </div>
                                <div class="topOne_text">第 - 值班组</div>
                                <div class="topOne_text">
                                    组长：<span style="margin: 0 10px">-</span>
                                    副组长：-
                                </div>
                            </div>
                            <div class="topOne_cord pp">
                                <div>
                                    <img
                                        style="width: 50px; height: 50px"
                                        :src="ppUrl"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div>批评（条）</div>
                                    <div class="num">{{ badNum || 0 }}</div>
                                </div>
                            </div>
                            <div class="topOne_cord by">
                                <div>
                                    <img
                                        style="width: 50px; height: 50px"
                                        :src="biaoYang"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div>表扬（条）</div>
                                    <div class="num">{{ goodNum || 0 }}</div>
                                </div>
                            </div>
                            <div class="topOne_cord jc">
                                <div>
                                    <img
                                        style="width: 50px; height: 50px"
                                        :src="rsUrl"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div>检查人数（人）</div>
                                    <div class="num">
                                        {{ checkUserNameListSize || 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="classDataList && classDataList.length > 0"
                        class="listStyle"
                    >
                        <div
                            class="each_table"
                            v-for="(item, index) in classDataList"
                            :key="index"
                        >
                            <div
                                class="top_row"
                                @click="changeBtnTableType(item)"
                            >
                                <div class="disFlex top_row_content">
                                    <div class="disFlex fontweight">
                                        {{ item.gradeName }}/{{
                                            item.className
                                        }}
                                    </div>
                                    <div
                                        class="masterName"
                                        :data-id="item.id"
                                    >
                                        班主任：{{ item.masterName }}
                                    </div>
                                    <el-tooltip
                                        v-if="masterNameTooltip[item.id]"
                                        class="masterNameTooltip"
                                        effect="dark"
                                        placement="bottom"
                                    >
                                        <img
                                            style="width: 14px; height: 14px"
                                            :src="moreUrl"
                                            alt=""
                                        />
                                        <div slot="content">
                                            {{ item.masterName }}
                                        </div>
                                    </el-tooltip>
                                    <div class="disFlex">
                                        <div class="score_img">
                                            <img
                                                :src="exportUrl"
                                                alt=""
                                                style="
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            />
                                        </div>
                                        <div class="all_score">小计：</div>
                                        <div
                                            :class="
                                                item.totalScore < 0
                                                    ? 'add_score'
                                                    : 'reduce_score'
                                            "
                                        >
                                            {{
                                                (item.totalScore < 0
                                                    ? ""
                                                    : "+") + item.totalScore
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        class="disFlex"
                                        style="margin-left: 20px"
                                    >
                                        <span>处理次数：</span>
                                        <span style="color: #c61f1f">{{
                                            item.totalHandle
                                        }}</span>
                                    </div>
                                </div>
                                <div class="more_img">
                                    <i
                                        class="el-icon-arrow-down"
                                        :class="{
                                            arrowTransform: item.btnFormType1,
                                        }"
                                    ></i>
                                </div>
                            </div>

                            <!-- 列表 -->
                            <table-data
                                ref="table"
                                :config="table_config"
                                :tableData="item.list"
                                v-if="item.btnFormType1"
                            >
                                <template #img="{data}">
                                    <div
                                        class="img-list"
                                        v-if="data.img.length > 0"
                                    >
                                        <div
                                            v-for="(
                                                subItem, subIndex
                                            ) in data.img"
                                            :key="subIndex"
                                            class="img-item"
                                        >
                                            <video
                                                v-if="
                                                    subItem
                                                        .toLowerCase()
                                                        .indexOf('mp4') > -1 ||
                                                    subItem
                                                        .toLowerCase()
                                                        .indexOf('mov') > -1
                                                "
                                                :class="subItem ? 'img2' : ''"
                                                disablePictureInPicture
                                                @click="handlePreview(subItem)"
                                            >
                                                <source :src="subItem" />
                                            </video>
                                            <img
                                                v-else
                                                :src="subItem"
                                                alt=""
                                                :class="subItem ? 'img2' : ''"
                                                @click="handleImg(subItem)"
                                            />
                                        </div>
                                    </div>
                                    <span v-else>-</span>
                                </template>
                            </table-data>
                        </div>
                    </div>
                    <div
                        v-else
                        class="empty-text"
                    >
                        暂无数据
                    </div>
                </div>
            </div>
        </div>
        <!-- 点击查看图片 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="handleCloseImg"
            :url-list="[viewPhoto]"
        />
        <!-- mov视频预览 -->
        <dialog-wrapper
            :dialog-obj="dialogMov"
            @handleClose="handleCloseVideo"
            class="dialog-form-video"
        >
            <video
                v-if="videoMovSrc"
                ref="videoElement"
                autoplay
                style="width: 100%; height: 100%"
                controls
                disablePictureInPicture
            >
                <source :src="videoMovSrc" />
            </video>
        </dialog-wrapper>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    // TableData,
    // 功能组件
    Error,
    Loading,
    debounce,
    DialogWrapper,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {mapState} from "vuex";
import {downloadFile} from "@/libs/utils.js";
import ExpandFilter from "./Sub/ExpandFilter.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {SManagerEduMoralStuDataModule} from "@/models/SManagerEduMoralStuData.js";
// import onResize from "@/mixins/onResize";

export default {
    name: "SManagerEduMoralReport",
    // mixins: [onResize],
    components: {
        ExpandFilter,
        // 功能组件
        Error,
        Loading,
        TableData,
        ElImageViewer,
        DialogWrapper,
    },

    data() {
        return {
            listQuery: {
                schoolId: "",
                ruleType: "",
                gradeId: "",
                classId: "",
                assessDateStart: "",
                assessDateEnd: "",
                dataScope: "",
                createByName: "", // 检查人
                handleId: "", // 处理方式
                haveHandle: "", // 处理状态
            },
            total: 0,
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            videoMovSrc: "",
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择类型",
                        key: "ruleType",
                        list: [
                            {
                                label: "表扬",
                                value: "2",
                            },
                            {
                                label: "批评",
                                value: "1",
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "检查人",
                        key: "createByName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "处理方式",
                        key: "handleId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "处理状态",
                        key: "haveHandle",
                        list: [
                            {
                                label: "已处理",
                                value: "1",
                            },
                            {
                                label: "未处理",
                                value: "0",
                            },
                        ],
                    },
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["SManagerEduMoralReport:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["SManagerEduMoralReport:list"],
                    },
                ],
                btnFormType: true,
            },
            btnFormType1: true,
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "学生",
                        prop: "studentName",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "考核行为",
                        prop: "behaviorName",
                        align: "center",
                    },
                    {
                        type: "function",
                        label: "分数",
                        prop: "score",
                        align: "center",
                        className: "text-spacing-reduction",
                        callBack(row) {
                            if (row.ruleType === "2") {
                                return `<div style="color:#71CE66;">+${row.score}</div>`;
                            } else if (row.ruleType === "1") {
                                return `<div style="color:#F99D5E;">-${row.score}</div>`;
                            } else {
                                return "-";
                            }
                        },
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "凭证",
                        type: "slot",
                        slotName: "img",
                        align: "center",
                        fixed: false,
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        type: "function",
                        label: "来源",
                        prop: "category",
                        align: "center",
                        className: "text-spacing-reduction",
                        callBack(row) {
                            if (row.category === "2") {
                                return "班级考核";
                            } else if (row.category === "3") {
                                return "宿舍考核";
                            } else {
                                return "学生考核";
                            }
                        },
                    },
                    {
                        label: "备注",
                        prop: "remark",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "考核时间",
                        prop: "assessDate",
                        align: "center",
                    },
                    {
                        label: "处理方式",
                        prop: "handleTypeName",
                        align: "center",
                        labelWidth: "146px",
                        type: "function",
                        callBack: (row) => {
                            return row.handleEntityList &&
                                row.handleEntityList[0]
                                ? row.handleEntityList[0].handleTypeName
                                : "-";
                        },
                    },
                    {
                        label: "处理人",
                        prop: "handler",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.handleEntityList &&
                                row.handleEntityList[0]
                                ? row.handleEntityList[0].createByName
                                : "-";
                        },
                    },
                    {
                        label: "处理日期",
                        prop: "handleDate",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.handleEntityList &&
                                row.handleEntityList[0]
                                ? row.handleEntityList[0].handleDate
                                : "-";
                        },
                    },
                ],
                check: false,
                height: "",
                refreshTable: false,
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            exportLoading: false,
            tableListSel: [],
            // 总计条数
            checkUserNameListSize: 0, // 检查人数
            goodNum: 0, // 表扬
            badNum: 0, // 批评
            checkUserNameList: [], // 检查人数组
            checkUserNameListBeyond: false,
            isShow: false,
            nowDate: "", // 当前日期
            nowTime: "", // 当前时间
            nowWeek: "", // 当前星期
            content: {
                height: "",
            },
            classDataList: [], // 列表数组
            masterNameTooltip: {},
            totalScore: 0, // 每个标记的小计
            gradeList: [],
            schoolCalendar: [],
            firstTime: true,
            showViewer: false,
            viewPhoto: "",
            sManagerEduMoralReportCheckPerson: false,
        };
    },
    created() {
        this.init();
    },
    mounted() {
        this.resizeHandler();
        this.currentTime();
        window.addEventListener("resize", this.handleResize);
    },
    // 销毁定时器
    beforeDestroy() {
        if (this.getDate) {
            clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
        }
        window.removeEventListener("resize", this.handleResize);
    },
    deactivated() {
        this.firstTime = true;
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.initBaseData("activated");
        }
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            schoolName: (state) => state.reportSchoolName,
            permissions: (state) => state.permissions,
        }),
        // 下载模板
        downLoadTempBtn() {
            return ["dormitoryDistribution:downLoadTemp"];
        },
        // 统计图标
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
        // 表扬图标
        praiseUrl() {
            return require("@/assets/images/moralAsstessment/honour-icon.png");
        },
        // 批评图标
        criticizeUrl() {
            return require("@/assets/images/moralAsstessment/smile-down-icon.png");
        },
        // 更多图标
        moreUrl() {
            return require("@/assets/images/moralAsstessment/showmore-icon.png");
        },
        // 批评图标
        ppUrl() {
            return require("@/assets/images/piPing.png");
        },
        // 表扬图标
        biaoYang() {
            return require("@/assets/images/biaoYang.png");
        },
        // 人数图标
        rsUrl() {
            return require("@/assets/images/renShu.png");
        },
    },
    methods: {
        init() {
            this.initAssignment();
            this.initFilter();
            this.initTableConfig();
            this.initBaseData("created");
        },
        initAssignment() {
            this.firstTime = false;
            this.listQuery.schoolId = this.schoolId;
        },
        initFilter() {
            let sManagerEduMoralReportDataRange = this.permissions.some(
                (item) => {
                    return "SManagerEduMoralReport:dataRange" == item;
                },
            );
            if (!sManagerEduMoralReportDataRange) return;
            let idx = 0,
                bol = false;
            let insertValue = {
                type: "select",
                label: "",
                value: "1",
                placeholder: "全部班级数据",
                key: "dataScope",
                list: [
                    {
                        label: "全部班级数据",
                        value: "1",
                    },
                    {
                        label: "不含本班考核数据",
                        value: "2",
                    },
                ],
            };
            this.formData.data.forEach((item, index) => {
                if (item.key == "classId") {
                    idx = index + 1;
                }

                if (item.key == "dataScope") {
                    bol = true;
                }
            });

            if (!bol) {
                this.formData.data.splice(idx, 0, insertValue);
                this.listQuery.dataScope = "1";
            }

            // 是否显示筛选宿舍考核数据
            let sManagerDormitoryMoralReportDataRange = this.permissions.some(
                (item) => {
                    return "moralmanage:moralreport:nohasroomdata" == item;
                },
            );
            if (!sManagerDormitoryMoralReportDataRange) return;
            let idxs = 4,
                bols = false;
            let insertValues = {
                type: "select",
                label: "",
                value: "1",
                placeholder: "全部考核数据",
                key: "category",
                list: [
                    {
                        label: "全部考核数据",
                        value: "1",
                    },
                    {
                        label: "不含宿舍考核数据",
                        value: "0",
                    },
                ],
            };
            this.formData.data.forEach((item, index) => {
                if (item.key == "category") {
                    bols = true;
                }
            });

            if (!bols) {
                this.formData.data.splice(idxs, 0, insertValues);
                this.listQuery.category = "1";
            }
        },
        initTableConfig() {
            this.sManagerEduMoralReportCheckPerson = this.permissions.some(
                (item) => {
                    return "SManagerEduMoralReport:checkPerson" == item;
                },
            );
            if (!this.sManagerEduMoralReportCheckPerson) return;

            let tableConfig = {
                    label: "检查人",
                    prop: "createByName",
                    align: "center",
                },
                theadIdx = 0,
                theadBol = false;

            this.table_config.thead.forEach((item, index) => {
                if (item.prop == "remark") {
                    theadIdx = index + 1;
                }

                if (item.prop == "createByName") {
                    theadBol = true;
                }
            });

            if (!theadBol) {
                this.table_config.thead.splice(theadIdx, 0, tableConfig);
            }
            console.log(
                this.formData.data,
                this.table_config.thead,
                "this.formData.data",
            );
        },
        /**
         * @Description: 点击图片放大
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 16:29:59
         * @param {*} data
         */
        handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        handleCloseImg() {
            this.showViewer = false;
        },
        handleCloseVideo() {
            this.$refs.videoElement.pause();
            this.dialogMov.dialogVisible = false;
            this.videoMovSrc = "";
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
        handlePreview(data) {
            console.log("预览视频", data);
            if (data.toLowerCase().indexOf("mov") > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = [
                    "swf",
                    "avi",
                    "flv",
                    "mpg",
                    "rm",
                    "mov",
                    "wav",
                    "asf",
                    "3gp",
                    "mkv",
                    "rmvb",
                    "mp4",
                ];
                let url = data.split("/");
                url = url[url.length - 1].split(".");
                url = url[url.length - 1];

                if (
                    data.indexOf("minio") !== -1 &&
                    videoType.indexOf(url) == -1
                ) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data);
                } else {
                    let url = decodeURIComponent(data); // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64;
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(
                        Base64.encode(url),
                    )}`;
                    window.open(previewUrl);
                }
            }
        },
        async initBaseData(key) {
            await this.getSchoolCalendar();
            await this.getGradeList();
            await this.getHandleTypeList();
            // if (key === "created") {

            // }
            this.setDefaultDate();
            this.getList();
        },
        /**
         * @Description: 获取年级
         * @DoWhat: 默认查询第一个选项
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:18
         */
        async getGradeList() {
            let params = {
                schoolId: this.schoolId,
                jobScope: ["2", "3"],
                needPermissionConfig: "0",
            };
            await this._fet(
                "/school/schoolOrgan/getGradeListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.gradeList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("gradeId", this.gradeList);
                }
            });
        },
        /**
         * @Description: 获取班级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:50:59
         */
        getClassList(gradeId) {
            let params = {
                schoolId: this.schoolId,
                gradeId: gradeId,
                jobScope: ["2", "3"],
                needPermissionConfig: "0",
            };
            this._fet(
                "/school/schoolOrgan/getClassListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    let classList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("classId", classList);
                }
            });
        },
        /**
         * @Description: 获取处理方式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: WGS
         * @Date: 2023-11-23 16:26:16
         */
        getHandleTypeList() {
            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            sManagerEduMoralStuDataModule
                .getMoralDisposeTypeList({
                    dictKey: "moralDisposeTypeGroup",
                })
                .then((res) => {
                    console.log("[getHandleTypeList]res:", res);
                    if (!res.error && res.data.code == "200") {
                        let handleTypeList = res.data.data.map((e) => ({
                            label: e.name,
                            value: e.id,
                        }));
                        this.setFormDataList("handleId", handleTypeList);
                    } else if (!res.error && res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        setFormDataList(type, list) {
            const index = this.formData.data.findIndex(
                (item) => item.key === type,
            );
            if (index > -1) {
                this.formData.data[index].list = list;
            }
        },
        /**
         * @Description: 查询校历
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-06 16:19:48
         */
        async getSchoolCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
            };
            await this._fet(
                "/school/schoolCalendar/listByCondition",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data;
                }
            });
        },
        /**
         * @Description: 设置默认查询时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 09:54:55
         */
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment()
                .startOf("month")
                .format("YYYY-MM-DD HH:mm");
            this.listQuery.assessDateEnd =
                this.$moment().format("YYYY-MM-DD HH:mm");
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        /**
         * @Description: 获取班级考核列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:03:25
         */
        getList() {
            this.classDataList = [];
            // 检查人清空
            this.checkUserNameList = [];
            this.checkUserNameListSize = "";
            this.loadingTable = true;
            this.isShow = true;
            this._fet(
                "/school/schoolMoralStudentDetail/getMoralReport",
                this.listQuery,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.badNum = res.data.data.badNum;
                        this.goodNum = res.data.data.goodNum;
                        this.checkUserNameListSize =
                            res.data.data.checkUserNameListSize;
                        if (res.data.data.checkUserNameList) {
                            this.checkUserNameList =
                                res.data.data.checkUserNameList.split(",");
                        }
                        if (this.checkUserNameList.length > 3) {
                            this.isShow = false;
                        }
                        this.calcCheckUserNameListBeyond();

                        res.data.data.classDataList.forEach((i) => {
                            i.btnFormType1 = true;
                            i.list.forEach((iv) => {
                                if (iv.img) {
                                    iv.img = iv.img.split(",");
                                } else {
                                    iv.img = [];
                                }
                            });
                        });
                        this.classDataList = res.data.data.classDataList;
                        this.classDataList.forEach((j, index) => {
                            j.id = index;
                            if (index === 0) {
                                j.btnFormType1 = true;
                            } else {
                                j.btnFormType1 = false;
                            }
                        });
                        console.log(
                            "[getList]this.classDataList:",
                            this.classDataList,
                        );
                        this.calcMasterNameBeyond();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        // 计算检查人是否展示省略号
        calcCheckUserNameListBeyond() {
            let timeout = 200;
            let dom = this.$refs.checkUserNameListName;
            if (dom) {
                dom.style.overflow = "visible";
                timeout = 0;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    const ev = this.$refs.checkUserNameListName;
                    const textRange = (el) => {
                        if (el) {
                            const textContent = el;
                            const targetW =
                                textContent.getBoundingClientRect().width;
                            const range = document.createRange();
                            range.setStart(textContent, 0);
                            range.setEnd(
                                textContent,
                                textContent.childNodes.length,
                            );
                            const rangeWidth =
                                range.getBoundingClientRect().width;
                            console.log(
                                "[calcCheckUserNameListBeyond][textRange]rangeWidth:",
                                rangeWidth,
                                "targetW:",
                                targetW,
                            );
                            return rangeWidth > targetW;
                        }
                    };
                    this.checkUserNameListBeyond = textRange(ev);
                    if (dom) {
                        dom.style.overflow = this.checkUserNameListBeyond
                            ? "hidden"
                            : "visible";
                    }
                    console.log(
                        "[calcCheckUserNameListBeyond]this.checkUserNameListBeyond:",
                        this.checkUserNameListBeyond,
                    );
                }, timeout);
            });
        },
        // 计算班主任是否展示省略号
        calcMasterNameBeyond() {
            let timeout = 200;
            let doms = document.getElementsByClassName("masterName");
            if (doms && doms.length > 0) {
                for (let dom of doms) {
                    dom.style.flex = "1";
                    dom.style.width = "0";
                    dom.style.overflow = "visible";
                }
                timeout = 0;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    const textRange = (el) => {
                        const textContent = el;
                        const targetW =
                            textContent.getBoundingClientRect().width;
                        const range = document.createRange();
                        range.setStart(textContent, 0);
                        range.setEnd(
                            textContent,
                            textContent.childNodes.length,
                        );
                        const rangeWidth = range.getBoundingClientRect().width;
                        console.log(
                            "[calcMasterNameBeyond][textRange]rangeWidth:",
                            rangeWidth,
                            "targetW:",
                            targetW,
                        );
                        return {
                            rangeWidth,
                            targetW,
                            isBeyond: rangeWidth > targetW,
                        };
                    };

                    let doms = document.getElementsByClassName("masterName");
                    if (doms && doms.length > 0) {
                        for (let dom of doms) {
                            // console.log("[calcMasterNameBeyond]dom:", dom.style.flex, dom.style.width)
                            let id = dom.getAttribute("data-id");
                            let {isBeyond} = textRange(dom);
                            console.log(
                                "[calcMasterNameBeyond]id:",
                                id,
                                "isBeyond:",
                                isBeyond,
                            );
                            if (isBeyond) {
                                dom.style.flex = "1";
                                dom.style.width = "0";
                                dom.style.overflow = "hidden";
                            } else {
                                dom.style.flex = "unset";
                                dom.style.width = "auto";
                                dom.style.overflow = "visible";
                            }
                            this.$set(this.masterNameTooltip, id, isBeyond);
                        }
                    }
                    // console.log("[calcMasterNameBeyond]this.masterNameTooltip:", this.masterNameTooltip)
                }, timeout);
            });
        },
        /**
         * @Description: 点击查询/重置按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:57
         * @param {*} data
         */
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    });
                    this.getList();
                    break;
                case "reset": // 重置
                    this.checkUserNameList = [];
                    this.classDataList = [];
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                        if (item.key === "classId") {
                            item.list = [];
                        }
                    });
                    this.setDefaultDate();
                    this.getList();
                    break;
                default:
                    break;
            }
        },
        // 修改年级查询项
        changeSel(data) {
            // 修改统计范围
            if (data.key === "gradeId") {
                const classIndex = this.formData.data.findIndex(
                    (i) => i.key === "classId",
                );
                this.formData.data[classIndex].value = "";
                this.formData.data[classIndex].list = [];
                if (data.value) {
                    this.getClassList(data.value);
                }
            }
        },
        // 条件筛选的 展开 / 收起
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.resizeHandler();
        },

        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:55:29
         */
        handleExport() {
            this.exportLoading = true;
            let form = this.listQuery;

            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/exportMoralReport",
                    form,
                },
                () => {
                    // this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },

        /**
         * @Description: 动态调整表格高度
         * @DoWhat: 初始化表格高度
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 10:50:04
         */
        resizeHandler() {
            this.$nextTick(() => {
                this.content.height = document.body.clientHeight - 265;
            });
        },
        /**
         * @Description: 获取当前时间
         * @DoWhat: 年月日时分秒星期
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:00:24
         */
        currentTime() {
            setInterval(this.getDate, 500);
        },
        /**
         * @Description: 获取时间戳
         * @DoWhat: 年月日时分秒星期
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:01:52
         */
        getDate() {
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let week = new Date().getDay();
            let hh = new Date().getHours();
            let ms =
                new Date().getSeconds() < 10
                    ? "0" + new Date().getSeconds()
                    : new Date().getSeconds();
            let mf =
                new Date().getMinutes() < 10
                    ? "0" + new Date().getMinutes()
                    : new Date().getMinutes();
            if (week == 1) {
                this.nowWeek = "星期一";
            } else if (week == 2) {
                this.nowWeek = "星期二";
            } else if (week == 3) {
                this.nowWeek = "星期三";
            } else if (week == 4) {
                this.nowWeek = "星期四";
            } else if (week == 5) {
                this.nowWeek = "星期五";
            } else if (week == 6) {
                this.nowWeek = "星期六";
            } else {
                this.nowWeek = "星期日";
            }
            _this.nowTime = hh + ":" + mf + ":" + ms;
            _this.nowDate = yy + "-" + mm + "-" + dd;
        },
        /**
         * @Description: table展开/折叠操作
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-07 17:00:23
         */
        changeBtnTableType(item) {
            item.btnFormType1 = !item.btnFormType1;
        },
        handleResize: debounce(function () {
            this.calcCheckUserNameListBeyond();
            this.calcMasterNameBeyond();
            this.resizeHandler();
        }, 200),
    },
};
</script>

<style scoped lang="scss">
.personName {
    color: #b6b9bf;
}
.el-tooltip__popper.is-dark {
    background-color: rgba(48, 49, 51, 0.9) !important;
}
.content-main {
    position: relative;
    overflow: hidden;
    // background-color: #fff;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 165px);
}

.filter_disFlex {
    margin-bottom: 10px;
    // border-bottom: 1px solid #edf0f2;
    border-radius: 0px 4px 4px 4px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    // margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.upload-demo {
    display: inline-block;
}

.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}

.el-button {
    font-size: 14px;
    margin-left: 10px;
}

.download-btn {
    border: 0;
    background-color: #3dc5f2;

    &:hover {
        background-color: #5ecff5;
    }
}

.drawerExam-container {
    ::v-deep .v-modal {
        position: absolute;
    }
}
.record_content {
    overflow: auto; // 超出这个最大高度的数据，会被隐藏起来，上下滑动
    overflow-x: hidden; //隐藏x轴滚动条
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    margin: 10px 0px 0px 10px;
}

.report_top_title_school {
    width: 100%;
    margin: 20px 0px 15px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .title {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        margin-right: 50px;
    }
    .subName {
        font-weight: 400;
        font-size: 14px;
        color: #2b2f33;
    }

    .checkUserNameList {
        .checkUserNameList-name {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.listStyle {
    width: 90%;
}
.report_top_title {
    text-align: center;
    margin: 15px auto;
    width: 90%;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .topOne {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        padding: 10px 20px;
        .topOne_mes {
            flex-shrink: 0;
            width: fit-content;
            margin-right: 50px;
            text-align: left;
            & > div:nth-of-type(2) {
                margin: 3px 0px;
            }
        }
        .time {
            font-weight: bold;
            font-size: 14px;
            color: #2b2f33;
        }
        .topOne_text {
            font-weight: 400;
            font-size: 14px;
            color: #2b2f33;
        }

        .topOne_cord {
            overflow: hidden;
            margin: 0px 20px;
            width: 190px;
            height: 63px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            & > div:nth-of-type(1) {
                flex-shrink: 0;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
            & > div:nth-of-type(2) {
                flex: 1;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                color: #2b2f33;
                .num {
                    font-weight: bold;
                    font-size: 20px;
                    color: #2b2f33;
                }
            }
        }
        .pp {
            background: #ffeded;
            border: 1px solid #ff7878;
        }
        .pp:hover {
            background: rgba($color: #ffeded, $alpha: 0.5);
        }
        .by {
            background: #f2ffed;
            border: 1px solid #29b41a;
        }
        .by:hover {
            background: rgba($color: #f2ffed, $alpha: 0.5);
        }
        .jc {
            background: #feedff;
            border: 1px solid #da41dc;
        }
        .jc:hover {
            background: rgba($color: #feedff, $alpha: 0.5);
        }
    }

    .report_top_title_alltotal {
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        margin: 0px 20px;
        margin-bottom: 20px;
    }

    .report_top_member {
        display: flex;
        font-size: 14px;
        margin: 0 auto;
        margin-bottom: 15px;
        width: 90%;
        align-items: center;

        .report_current_time {
            margin-right: 10px;
        }

        .report_rightLine {
            width: 1px;
            height: 16px;
            font-weight: bold;
            background: #e1e3e6;
            margin: 0 20px;
        }
    }
}

.disFlex {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.close_text {
    color: #66bfff;
    cursor: pointer;
}

.score_img {
    margin-right: 5px;
    line-height: 10px;
}

::v-deep .el-table {
    border: 1px solid #ebeef5;
}
.each_table {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 auto;
    padding: 5px 20px;
    margin-bottom: 10px;
    .top_row {
        // border: 1px solid #ebeef5;
        // height: 40px;
        display: flex;
        font-size: 14px;
        line-height: 40px;
        justify-content: space-between;
        transition: all 0.4s;
        padding-left: 30px;
        .disFlex {
            display: flex;
            white-space: nowrap;
        }
        .top_row_content {
            flex: 1;
            padding-right: 20px;
        }
        .masterName {
            flex: 1;
            width: 0;
            overflow: visible;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 20px;
        }
        .masterName + .masterNameTooltip {
            margin-left: -20px;
            margin-right: 20px;
        }
        .fontweight {
            font-weight: bold;
        }
        .score_img {
            margin-top: 2px;
        }
        .more_img {
            margin-top: 2px;
            cursor: pointer;
        }
        .el-icon-arrow-down {
            transition: 0.2s;
            line-height: 40px;
            color: #d4d7d9;
            font-weight: bold;
        }
        .arrowTransform {
            transform: rotateZ(180deg);
        }

        .all_score {
            margin-left: 7px;
        }

        .add_score {
            color: #f99d5e;
            margin-left: 4px;
        }

        .reduce_score {
            color: #71ce66;
            margin-left: 4px;
        }
    }
    // .top_row:hover {
    //     cursor: pointer;
    //     box-shadow: 3px 3px 10px 3px rgba(80, 79, 79, 0.1);
    //     transform: scale(1.01);
    // }
}
.img2 {
    // width: 60px;
    // width: 100%;
    max-width: 100%;
    max-height: 100%;
}
.img-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-item {
        margin: 2px 0;
        flex-shrink: 0;
        background-color: #333;
        width: 50px;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.empty-text {
    text-align: center;
    padding: 50px;
    color: #909399;
}
::v-deep .el-table .el-table__row {
    border: none;
}
::v-deep .el-table {
    border: none;
}
.dialog-form-video {
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px !important;
    }
}
</style>
